import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './bottom-caption.styles';

const bottomCaption = ({ bottomCaption }) => {

  return (
    <Container>
      <section className="w-4/5 md:w-1/2 mx-auto font-micro font-light leading-normal text-base sm:text-lg tracking-tight text-brand-gray">
        <p>{bottomCaption.bottomCaption}</p>
      </section>
    </Container>
  );
};

bottomCaption.propTypes = {
  bottomCaption: PropTypes.string.isRequired,
};

export default bottomCaption;
